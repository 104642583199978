import FontFaceObserver from "fontfaceobserver";
import { sample, isArray } from "lodash";

import { typeStyles } from "./typeStyles";
import { textOffset } from "./getHeight";
import { allIcons, possibleIcons } from "./loadFonts";

function getSVGDOM(icon) {
  const i = icon || allIcons[icon] || sample(possibleIcons);
  return fetch(i)
    .then((resp) => resp.text())
    .then((text) => new DOMParser().parseFromString(text, "image/svg+xml"));
}

function loadSVGImage(svgel) {
  const markup = new XMLSerializer().serializeToString(svgel);
  const img = new Image();
  return new Promise((res, rej) => {
    img.onload = (e) => res(img);
    img.onerror = rej;
    img.src = `data:image/svg+xml,${encodeURIComponent(markup)}`;
  });
}

async function prepareAssets(color, icon) {
  const svgDoc = await getSVGDOM(icon);
  const originalImage = loadSVGImage(svgDoc);
  svgDoc.querySelectorAll("svg").forEach((el) => {
    el.setAttribute("fill", color);
    el.setAttribute("preserveAspectRatio", "xMidYMid meet");
  });
  const coloredImage = loadSVGImage(svgDoc);
  const iconScale = icon === "squirrel" ? 0.8 : 1;
  return Promise.all([originalImage, coloredImage, iconScale]);
}

export const createCollarlab = async (
  canvas,
  tempCanvas,
  setLoading,
  loading,
  name,
  sz,
  backgroundColor,
  primaryColor,
  secondaryColor,
  iconColor,
  type,
  icon,
  setHasLoaded,
  flip = false
) => {
  console.log("create collarlab");
  // create canvas and reset
  // setLoading(true);
  // const dpr = window.devicePixelRatio || 1;
  const dpr = 1;
  const ctx = tempCanvas.getContext("2d");
  const context = canvas.getContext("2d");
  ctx.clearRect(0, 0, tempCanvas.width, tempCanvas.height);
  ctx.canvas.width = 8000 * dpr;
  ctx.canvas.height = 300 * sz * dpr;

  ctx.fillStyle = backgroundColor;
  ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

  const gutter = 60;
  let xPos = gutter / 2;
  const yMid = ctx.canvas.height / 2;
  const yMidTop = ctx.canvas.height / 4 + 10 * sz;
  const yMidBottom = (ctx.canvas.height * 3) / 4 - 10 * sz;
  // ctx.scale(dpr, dpr);

  const {
    fontFamily,
    fontSize,
    offset = 0,
    style = "single",
  } = await typeStyles(type);

  // load icons, supporting both array and string input
  const icons = [];
  if (icon === "icon-1") {
    icons.push(await prepareAssets(iconColor));
  }
  if (icon === "icon-2") {
    icons.push(await prepareAssets(iconColor));
    icons.push(await prepareAssets(iconColor));
  }
  if (isArray(icon) && icon.length > 0) {
    await Promise.all(
      icon.map(async (i) => {
        icons.push(await prepareAssets(iconColor, i.file || i));
      })
    );
  }

  // load font
  const fs = fontSize * sz;
  const font = new FontFaceObserver(fontFamily);
  await font
    .load()
    .then(() => {
      console.log("Font file has loaded.");
    })
    .catch(() => {
      console.log("Font file failed to load.");
    });

  // text
  const drawText = (align, color, x, y, size = "normal", flip = "") => {
    const fontString = `${size === "small" ? fs * 0.66 : fs}px ${fontFamily}`;
    ctx.font = fontString;
    ctx.textBaseline = "alphabetical";
    ctx.fillStyle = color;

    if (flip === "flip") {
      ctx.save();
      ctx.textAlign = "right";
      ctx.translate(x, y);
      ctx.scale(-1, -1);
      ctx.fillText(name, 0, textOffset(name, fs, fontString) - offset);
      ctx.restore();
    } else {
      ctx.save();
      ctx.textAlign = "left";
      ctx.translate(x, y);
      ctx.fillText(name, 0, textOffset(name, fs, fontString) - offset);
      ctx.restore();
    }
  };
  // icon
  const drawIcon = (icn, x, iconScale = 1) => {
    xPos -= gutter / 2;
    const maxIconWidth = 200 * sz;
    const maxIconHeight = 200 * sz;
    const scale =
      Math.min(maxIconWidth / icn.width, maxIconHeight / icn.height) *
      iconScale;
    const y = (ctx.canvas.height - icn.height * scale) / 2;
    ctx.drawImage(
      icn,
      x - gutter / 2,
      y,
      icn.width * scale,
      icn.height * scale
    );
    xPos += icn.width * scale;
    xPos -= gutter / 2;
  };

  if (style === "single") {
    drawText("middle", primaryColor, xPos, yMid);
  } else {
    drawText("bottom", secondaryColor, xPos, yMidTop, "small");
    drawText(
      "top",
      primaryColor,
      xPos,
      yMidBottom,
      "small",
      style === "2-up-flip" ? "flip" : ""
    );
  }
  xPos += ctx.measureText(name).width;

  if (icons.length > 0) {
    xPos += gutter;
    drawIcon(icons[0][1], xPos, icons[2]);
  }

  // second name style, when applicable
  if (style === "alternating") {
    xPos += gutter;
    ctx.font = `${fs}px ${fontFamily}`;
    drawText("middle", primaryColor, xPos, (300 * sz) / 2);
    xPos += ctx.measureText(name).width;

    if (icons.length == 1) {
      // one icon - repeat
      xPos += gutter;
      drawIcon(icons[0][1], xPos, icons[2]);
    } else if (icons.length == 2) {
      // two icons
      xPos += gutter;
      drawIcon(icons[1][1], xPos, icons[2]);
    }
  } else if (icons.length == 2) {
    // not alternating, but needs repeated second icon and then name
    xPos += gutter;
    if (style === "single") {
      drawText("middle", secondaryColor, xPos, yMid);
    } else {
      drawText("bottom", primaryColor, xPos, yMidTop, "small");
      drawText(
        "top",
        secondaryColor,
        xPos,
        yMidBottom,
        "small",
        style === "2-up-flip" ? "flip" : ""
      );
    }

    xPos += ctx.measureText(name).width;

    if (icons.length > 0) {
      xPos += gutter;
      drawIcon(icons[1][1], xPos, icons[2]);
    }
  }
  // ///// second
  xPos += gutter;

  // text
  if (style === "single") {
    drawText(
      "middle",
      icons.length == 2 ? primaryColor : secondaryColor,
      xPos,
      yMid
    );
  } else {
    drawText(
      "bottom",
      icons.length == 2 ? secondaryColor : primaryColor,
      xPos,
      yMidTop,
      "small"
    );
    drawText(
      "top",
      icons.length == 2 ? primaryColor : secondaryColor,
      xPos,
      yMidBottom,
      "small",
      style === "2-up-flip" ? "flip" : ""
    );
  }

  xPos += ctx.measureText(name).width;

  // circle / icon
  if (icons.length > 0) {
    xPos += gutter;
    drawIcon(icons[0][1], xPos, icons[2]);
  }

  // second name style, when applicable
  if (style === "alternating") {
    xPos += gutter;
    ctx.font = `${fs}px ${fontFamily}`;
    drawText("middle", secondaryColor, xPos, yMid);
    xPos += ctx.measureText(name).width;

    if (icons.length == 1) {
      // one icon - repeat
      xPos += gutter;
      drawIcon(icons[0][1], xPos, icons[2]);
    } else if (icons.length == 2) {
      // two icons
      xPos += gutter;
      drawIcon(icons[1][1], xPos, icons[2]);
    }
  } else if (icons.length == 2) {
    // not alternating, but needs repeated second icon and then name
    xPos += gutter;
    if (style === "single") {
      drawText("middle", secondaryColor, xPos, yMid);
    } else {
      drawText("bottom", primaryColor, xPos, yMidTop, "small");
      drawText(
        "top",
        secondaryColor,
        xPos,
        yMidBottom,
        "small",
        style === "2-up-flip" ? "flip" : ""
      );
    }

    xPos += ctx.measureText(name).width;
    xPos += gutter;
    drawIcon(icons[1][1], xPos, icons[2]);
  }

  xPos += gutter / 2;

  // resize
  if (flip) {
    canvas.width = 300 * sz * dpr;
    canvas.height = xPos * dpr;
    context.save();
    context.translate(0, 0);
    context.rotate((90 * Math.PI) / 180);
    context.translate(-0, -(300 * sz * dpr));
    context.drawImage(tempCanvas, 0, 0);
    context.restore();
  } else {
    canvas.width = xPos * dpr;
    canvas.height = 300 * sz * dpr;
    context.drawImage(tempCanvas, 0, 0);
  }

  console.log("finish collarlab");

  setLoading(false);
  setHasLoaded(true);
};

export const getObjURL = async (
  name,
  sz,
  backgroundColor,
  primaryColor,
  secondaryColor,
  iconColor,
  type,
  icon
) => {
  const canvas = document.createElement("canvas");
  const tempCanvas = document.createElement("canvas");

  const setLoading = () => {};
  const loading = false;
  const setHasLoaded = () => {};
  await createCollarlab(
    canvas,
    tempCanvas,
    setLoading,
    loading,
    name,
    sz,
    backgroundColor,
    primaryColor,
    secondaryColor,
    iconColor,
    type,
    icon,
    setHasLoaded,
    false
  );
  console.log("getObjURL");

  const blob = await new Promise((resolve) =>
    canvas.toBlob(resolve, "image/png")
  );

  const url = URL.createObjectURL(blob);
  return url;
};

export default createCollarlab;
