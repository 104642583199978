import React from "react";
import { Link, navigate } from "gatsby";
import { Helmet } from "react-helmet";
import { useRecoilValue, useRecoilState } from "recoil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faArrowCircleLeft,
  faTimes
} from "@fortawesome/fontawesome-pro-light";

import Cart from "../components/Cart/Cart";

import { useShopifyFunctions } from "../utils/useShopifyFunctions";
import { cartAtom, showCartAtom } from "../utils/atoms";

import "./index.css";

export default function MainLayout({ children, location, back, backLabel }) {
  const { checkout } = useShopifyFunctions();
  const cart = useRecoilValue(cartAtom);
  const [showCart, setShowCart] = useRecoilState(showCartAtom);

  const shortNav =
    location &&
    (location.pathname === "/collarlab-custom" ||
      location.pathname === "/collarlab-custom/" ||
      location.pathname === "/spid-custom" ||
      location.pathname === "/spid-custom/");

  return (
    <main className="bg-sp-gray text-gray-700 min-h-screen">
      <Helmet>
        <meta name="description" content="Hero Forms" />
        <html lang="en" />
      </Helmet>
      <div className="">
        <nav
          className={`${
            (shortNav || showCart ) ? "w-2/3" : "w-full"
          } fixed z-10 left-0 top-0 flex justify-between items-center p-4`}
        >
          {location && location.pathname !== "/" && back ? (
            <button type="button" className="flex items-center" onClick={back}>
              <FontAwesomeIcon size="3x" icon={faArrowCircleLeft} />
              <span className="ml-2 font-bold">{backLabel || 'Back'}</span>
            </button>
          ) : (
            <div />
          )}
          <Link to="/" className={location.pathname === "/" ? "opacity-0 pointer-events-none absolute" : "absolute left-1/2 transform -translate-x-1/2"}>
            <svg className="h-10" x="0px" y="0px" viewBox="0 0 167.6 49.7">
              <path
                d="M35.7,14.6c2.5,2.7,4,6.3,4,10.2c0,8.2-6.7,14.9-14.9,14.9c-4,0-7.5-1.5-10.2-4l-7,7c4.5,4.3,10.5,7,17.2,7
                  c13.7,0,24.8-11.1,24.8-24.8V0.7L35.7,14.6"
              />
              <path
                d="M14,35.1c-2.5-2.7-4-6.3-4-10.2c0-8.2,6.7-14.9,14.9-14.9c3.9,0,7.5,1.5,10.2,4L49,0H24.9C11.1,0,0,11.1,0,24.8
                  c0,6.7,2.7,12.8,7,17.2L14,35.1"
              />
              <path
                d="M67.7,23.6c-2,0-4-0.3-5.8-1c-1.8-0.6-3.5-1.6-4.9-2.9l3.5-4.2c2.3,1.8,4.7,2.7,7.4,2.7c0.8,0,1.5-0.1,1.9-0.4
                  c0.4-0.3,0.7-0.7,0.7-1.2v-0.1c0-0.2-0.1-0.5-0.2-0.6c-0.1-0.2-0.3-0.4-0.6-0.5c-0.3-0.2-0.7-0.3-1.2-0.5c-0.5-0.2-1.1-0.3-1.9-0.5
                  c-1.2-0.3-2.4-0.6-3.4-0.9c-1-0.3-2-0.8-2.7-1.3c-0.8-0.5-1.4-1.2-1.8-2c-0.4-0.8-0.7-1.8-0.7-2.9V7.3c0-1.1,0.2-2,0.6-2.9
                  c0.4-0.9,1-1.7,1.8-2.3c0.8-0.7,1.7-1.2,2.8-1.5C64.4,0.2,65.6,0,67,0c2,0,3.7,0.3,5.2,0.8c1.5,0.5,2.9,1.3,4.2,2.3l-3.2,4.5
                  c-1-0.7-2.1-1.3-3.2-1.7c-1.1-0.4-2.2-0.6-3.2-0.6c-0.8,0-1.3,0.1-1.7,0.4c-0.4,0.3-0.6,0.6-0.6,1v0.1c0,0.3,0.1,0.5,0.2,0.7
                  C64.8,7.7,65,7.9,65.3,8c0.3,0.2,0.7,0.3,1.2,0.5C67,8.7,67.7,8.8,68.5,9c1.3,0.3,2.5,0.6,3.5,1c1.1,0.4,2,0.8,2.7,1.4
                  c0.7,0.5,1.3,1.2,1.7,2c0.4,0.8,0.6,1.7,0.6,2.7v0.1c0,1.2-0.2,2.2-0.7,3.1c-0.4,0.9-1.1,1.7-1.9,2.3c-0.8,0.6-1.8,1.1-2.9,1.5
                  C70.4,23.4,69.1,23.6,67.7,23.6"
              />
              <polyline points="85.3,5.9 78.4,5.9 78.4,0.4 98.4,0.4 98.4,5.9 91.6,5.9 91.6,23.2 85.3,23.2 85.3,5.9 " />
              <path
                d="M111.2,23.6c-1.6,0-3.1-0.2-4.4-0.6c-1.3-0.4-2.4-1-3.3-1.9c-0.9-0.9-1.6-1.9-2.1-3.3c-0.5-1.3-0.7-2.9-0.7-4.6V0.4h6.5
                  v12.7c0,1.7,0.4,2.9,1.1,3.7c0.8,0.8,1.8,1.2,3,1.2c1.3,0,2.3-0.4,3-1.1c0.8-0.8,1.1-1.9,1.1-3.6V0.4h6.5V13c0,1.8-0.3,3.4-0.8,4.8
                  c-0.5,1.3-1.2,2.4-2.2,3.3c-0.9,0.9-2,1.5-3.4,1.9C114.3,23.4,112.8,23.6,111.2,23.6"
              />
              <polyline
                points="124,0.4 129.9,0.4 139.3,12.5 139.3,0.4 145.5,0.4 145.5,23.2 140,23.2 130.2,10.7 130.2,23.2 124,23.2 124,0.4
                  "
              />
              <polyline points="154.4,5.9 147.6,5.9 147.6,0.4 167.6,0.4 167.6,5.9 160.7,5.9 160.7,23.2 154.4,23.2 154.4,5.9 " />
              <path
                d="M58.8,26.5h9.8c1.4,0,2.7,0.2,3.9,0.5c1.2,0.4,2.2,0.9,3,1.6c0.8,0.7,1.5,1.5,1.9,2.5c0.4,1,0.7,2.1,0.7,3.4v0.1
                  c0,1.3-0.2,2.5-0.7,3.6c-0.5,1-1.2,1.9-2,2.6c-0.9,0.7-1.9,1.2-3.1,1.6c-1.2,0.3-2.5,0.5-3.9,0.5h-3.1v6.5h-6.3V26.5 M68.1,37.8
                  c1.1,0,2-0.3,2.7-0.8c0.6-0.5,1-1.3,1-2.2v-0.1c0-1-0.3-1.7-1-2.2c-0.6-0.5-1.5-0.8-2.7-0.8h-3v6.1H68.1z"
              />
              <path
                d="M90.6,49.7c-1.6,0-3.1-0.2-4.4-0.6c-1.3-0.4-2.4-1-3.3-1.9c-0.9-0.9-1.6-1.9-2.1-3.3c-0.5-1.3-0.7-2.9-0.7-4.6V26.5h6.5
                  v12.7c0,1.7,0.4,2.9,1.1,3.7c0.8,0.8,1.8,1.2,3,1.2c1.3,0,2.3-0.4,3-1.1c0.8-0.8,1.1-1.9,1.1-3.6V26.5h6.5v12.6
                  c0,1.8-0.3,3.4-0.8,4.8c-0.5,1.3-1.2,2.4-2.2,3.3c-0.9,0.9-2,1.5-3.4,1.9C93.7,49.5,92.2,49.7,90.6,49.7"
              />
              <path
                d="M103.4,26.5h9.8c1.4,0,2.7,0.2,3.9,0.5c1.2,0.4,2.2,0.9,3,1.6c0.8,0.7,1.5,1.5,1.9,2.5c0.4,1,0.7,2.1,0.7,3.4v0.1
                  c0,1.3-0.2,2.5-0.7,3.6c-0.5,1-1.2,1.9-2,2.6c-0.9,0.7-1.9,1.2-3.1,1.6s-2.5,0.5-3.9,0.5h-3.1v6.5h-6.3V26.5 M112.7,37.8
                  c1.1,0,2-0.3,2.7-0.8c0.6-0.5,1-1.3,1-2.2v-0.1c0-1-0.3-1.7-1-2.2c-0.6-0.5-1.5-0.8-2.7-0.8h-3v6.1H112.7z"
              />
              <path
                d="M124.6,26.5h9.8c1.4,0,2.7,0.2,3.9,0.5c1.2,0.4,2.2,0.9,3,1.6c0.8,0.7,1.5,1.5,1.9,2.5c0.4,1,0.7,2.1,0.7,3.4v0.1
                  c0,1.3-0.2,2.5-0.7,3.6c-0.5,1-1.2,1.9-2,2.6c-0.9,0.7-1.9,1.2-3.1,1.6c-1.2,0.3-2.5,0.5-3.9,0.5H131v6.5h-6.3V26.5 M134,37.8
                  c1.1,0,2-0.3,2.7-0.8c0.6-0.5,1-1.3,1-2.2v-0.1c0-1-0.3-1.7-1-2.2c-0.6-0.5-1.5-0.8-2.7-0.8h-3v6.1H134z"
              />
              <polyline
                points="151.5,40.7 142.9,26.5 150.1,26.5 154.8,34.8 159.5,26.5 166.6,26.5 157.9,40.6 157.9,49.3 151.5,49.3 151.5,40.7
                  "
              />
              Stunt Puppy
            </svg>
            {/* <br />
              Collarlab */}
          </Link>
          <button
            type="button"
            // onClick={() => navigate("/cart")}
            onClick={() => setShowCart(!showCart)}
            className={`${showCart ? "bg-white -mt-4 -mr-4 mr-4 mt-4" : ""} flex items-center p-4`}
          >
            {showCart ?
              <>
                <span className="mr-2 font-bold">Close</span>
                <FontAwesomeIcon size="2x" icon={faTimes} />
              </>
            : <>
                <span className="mr-2 font-bold">Cart ({cart && cart.length})</span>
                <FontAwesomeIcon size="2x" icon={faShoppingCart} />
              </>
            }
          </button>
        </nav>
        {children}
        {(showCart || location && location.pathname === "/" )&& (
          <>
            {location && location.pathname !== "/" && <div className="fixed left-0 top-0 bottom-0 w-2/3 bg-black bg-opacity-40" />}
            <div className="fixed right-0 top-0 bottom-0 w-1/3 bg-white shadow-lg z-30">
              <Cart />
            </div>
          </>
        )}
      </div>
    </main>
  );
}
