export const createSpid = (
  canvas,
  tempCanvas,
  setLoading,
  loading,
  sz,
  img,
  setHasLoaded,
  flip = false,
) => {
  // create canvas and reset
  // setLoading(true);
  const dpr = 1;
  const ctx = tempCanvas.getContext("2d");
  const context = canvas.getContext("2d");
  ctx.clearRect(0, 0, tempCanvas.width, tempCanvas.height);
  ctx.canvas.width = 8000 * dpr;
  ctx.canvas.height = 300 * sz * dpr;

  // ctx.fillStyle = backgroundColor;
  ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  let xPos = 0;
  const aspectratio = ctx.canvas.height / img.width
  console.log(img)
  console.log(aspectratio)

  ctx.save();
  ctx.rotate(90*Math.PI/180);
  ctx.drawImage(img, -0, -img.height * aspectratio, img.width * aspectratio, img.height * aspectratio);
  // ctx.drawImage(img, 0, 0 )
  // console.log(img.width)
  // console.log(canvas.height)
  // console.log(canvas.height / img.width)
  ctx.restore();  
  xPos += img.height * aspectratio;

    // resize
  if (flip) {
    canvas.width = 300 * sz * dpr;
    canvas.height = xPos * dpr;
    context.save();
    context.translate(0, 0);
    context.rotate((90 * Math.PI) / 180);
    context.translate(-0, -(300 * sz * dpr));
    context.drawImage(tempCanvas, 0, 0);
    context.restore();
  } else {
    canvas.width = xPos * dpr;
    canvas.height = 300 * sz * dpr;
    context.drawImage(tempCanvas, 0, 0);
  }

  setLoading(false);
  setHasLoaded(true);
};

export default createSpid;
