import React, { memo, useRef, useState, useEffect } from "react";
import { createSpid } from "../../utils/createSpid";

const SpidImg = ({
  flip = false,
  placeholder,
  sz,
  pattern,
  alt = "",
  size = "5rem",
}) => {
  const canvas = useRef(document.createElement("canvas"));
  const tempCanvas = useRef(document.createElement("canvas"));
  const [localLoading, setLocalLoading] = useState(true);
  const [currentSrc, updateSrc] = useState(placeholder);

  useEffect(() => {
    const setLoading = () => {};
    const loading = false;
    const setHasLoaded = () => {};
    const isClient = typeof window === "object";

    if (!isClient) return;

    const createToBlob = async (img) => {
      await createSpid(
        canvas.current,
        tempCanvas.current,
        setLoading,
        loading,
        sz,
        img,
        setHasLoaded,
        flip,
      );

      canvas.current.toBlob((blob) => {

        if(blob) {
          const urlCreator = window.URL || window.webkitURL;
          const url = urlCreator.createObjectURL(blob);

          const imageToLoad = new Image();
          imageToLoad.src = url;
          // imageToLoad.srcObject = blob;

          imageToLoad.onload = () => {
            console.log("img onload");
            // When image is loaded replace the src and set loading to false
            setLocalLoading(false);
            updateSrc(url);
          };
        }
      }, "image/png");
    };
    const img = new Image();
    img.src = pattern;
    img.onload = () => {
      createToBlob(img);
    }
  }, [sz, pattern]
  );
  if (flip)
    return (
      <div
        style={{
          backgroundRepeat: "repeat-y",
          backgroundImage: `url(${currentSrc})`,
          backgroundSize: "100% auto",
          height: "100%",
          width: size,
          opacity: localLoading ? 0.5 : 1,
          transition: "opacity .15s linear",
        }}
        alt={alt}
      />
    );

  return (
    <div
      // src={currentSrc}
      style={{
        backgroundRepeat: "repeat-x",
        backgroundImage: `url(${currentSrc})`,
        backgroundSize: "auto 100%",
        height: size,
        width: "100%",
        opacity: localLoading ? 0.5 : 1,
        transition: "opacity .15s linear",
      }}
      alt={alt}
    />
  );
};

export default SpidImg;
