import React, { memo, useRef, useState, useEffect } from "react";
import { createCollarlab } from "../../utils/createCollarlab";

const CollarlabImg = ({
  flip = false,
  name,
  sz,
  backgroundColor,
  primaryColor,
  secondaryColor,
  iconColor,
  type,
  icon,
  placeholder,
  alt = "",
  size = "5rem",
}) => {
  const canvas = useRef(document.createElement("canvas"));
  const tempCanvas = useRef(document.createElement("canvas"));
  const [localLoading, setLocalLoading] = useState(true);
  const [currentSrc, updateSrc] = useState(placeholder);

  useEffect(() => {
    // temp
    const setLoading = () => {};
    const loading = false;
    const setHasLoaded = () => {};
    const isClient = typeof window === "object";

    if (!isClient) return;

    console.log("is client");

    const createToBlob = async () => {
      await createCollarlab(
        canvas.current,
        tempCanvas.current,
        setLoading,
        loading,
        name,
        sz,
        backgroundColor,
        primaryColor,
        secondaryColor,
        iconColor,
        type,
        icon,
        setHasLoaded,
        flip
      );
      console.log("toblob");
      canvas.current.toBlob((blob) => {
        const urlCreator = window.URL || window.webkitURL;
        const url = urlCreator.createObjectURL(blob);

        const imageToLoad = new Image();
        imageToLoad.src = url;
        // imageToLoad.srcObject = blob;

        imageToLoad.onload = () => {
          console.log("img onload");
          // When image is loaded replace the src and set loading to false
          setLocalLoading(false);
          updateSrc(url);
        };
      }, "image/png");
    };
    createToBlob();

    // const blob = new Promise((resolve) =>
    //   canvas.current.toBlob(resolve, "image/png")
    // );

    // const url = window.URL.createObjectURL(blob);
    // start loading original image
  }, [
    name,
    sz,
    backgroundColor,
    primaryColor,
    secondaryColor,
    iconColor,
    type,
    icon,
  ]);
  if (flip)
    return (
      <div
        // src={currentSrc}
        style={{
          backgroundRepeat: "repeat-y",
          backgroundImage: `url(${currentSrc})`,
          backgroundSize: "100% auto",
          height: "100%",
          width: size,
          opacity: localLoading ? 0.5 : 1,
          transition: "opacity .15s linear",
        }}
        alt={alt}
      />
    );

  return (
    <div
      // src={currentSrc}
      style={{
        backgroundRepeat: "repeat-x",
        backgroundImage: `url(${currentSrc})`,
        backgroundSize: "auto 100%",
        height: size,
        width: "100%",
        opacity: localLoading ? 0.5 : 1,
        transition: "opacity .15s linear",
      }}
      alt={alt}
    />
  );
};

export default CollarlabImg;
