import React from "react";

const StepWrap = ({ title, children }) => (
  <div className="scroll-shadow-wrap">
    <div className="scroll-shadow-content">
      <div className="flex flex-col mx-4">
        <h2 className="text-lg font-bold mx-4 rounded-t-lg text-center pb-8">
          {title}
        </h2>
        {children}
      </div>
    </div>
  </div>   
);

export default StepWrap;
