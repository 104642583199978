function fontheight(text, fs, font) {
  const canvas = document.createElement("canvas");
  canvas.width = 1000;
  canvas.height = 1000;
  const ctx = canvas.getContext("2d");

  function measureTextHeight(left, top, width, height, text) {
    // Draw the text in the specified area
    ctx.save();
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.textBaseline = "top";
    ctx.fillText(text, 0, fs); // This seems like tall text...  Doesn't it?
    ctx.restore();

    // Get the pixel data from the canvas
    const { data } = ctx.getImageData(left, top, width, height);
    let first = false;
    let last = false;
    let r = height;
    let c = 0;

    // Find the last line with a non-white pixel
    while (!last && r) {
      r--;
      for (c = 0; c < width; c++) {
        if (data[r * width * 4 + c * 4 + 3]) {
          last = r;
          break;
        }
      }
    }

    // Find the first line with a non-white pixel
    while (r) {
      r--;
      for (c = 0; c < width; c++) {
        if (data[r * width * 4 + c * 4 + 3]) {
          first = r;
          break;
        }
      }

      // If we've got it then return the height
      //   console.log(first);
      //   console.log(last);
      if (first != r) return last - first;
    }
    return 0;
  }
  ctx.font = font || "36px arial";
  const height = measureTextHeight(0, 0, canvas.width, canvas.height, text);
  // return the height of the string
  // console.log("height");
  // console.log(height);
  return height;
} // end $(function(){});

function fontheight2(text, fs, font) {
  const canvas = document.createElement("canvas");
  canvas.width = 1000;
  canvas.height = 1000;
  const ctx = canvas.getContext("2d");

  function measureTextHeight(left, top, width, height, text) {
    // Draw the text in the specified area
    ctx.save();
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.textBaseline = "alphebetical";
    ctx.fillText(text, 0, 0); // This seems like tall text...  Doesn't it?
    ctx.restore();

    // Get the pixel data from the canvas
    const { data } = ctx.getImageData(left, top, width, height);
    const first = false;
    let last = false;
    let r = height;
    let c = 0;

    while (!last && r) {
      r--;
      for (c = 0; c < width; c++) {
        if (data[r * width * 4 + c * 4 + 3]) {
          last = r;
          break;
        }
      }
    }
    // If we've got it then return the height
    // if (first != r) return fs - 1 - first;
    if (last) return last;

    return 0;
  }

  ctx.font = font || "36px arial";
  const height = measureTextHeight(0, 0, canvas.width, canvas.height, text);
  // console.log(height);
  // return the height of the string
  return height;
} // end $(function(){});

export function textOffset(text, fs, font) {
  const textheight = fontheight(text, fs, font);
  const textheight2 = fontheight2(text, fs, font);
  const difference = textheight - textheight2;
  // console.log("difference");
  // console.log(difference);
  // console.log(`textheight: ${textheight}`);
  // console.log(`textheight2: ${textheight2}`);
  // console.log(`difference: ${textheight - textheight2}`);
  // console.log(`offset: ${0 - difference + textheight / 2}`);
  return difference / 2;
}

export default textOffset;
