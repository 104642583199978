import ball from "../images/Icons/Ball.svg";
import bark from "../images/Icons/Bark.svg";
import bird from "../images/Icons/Bird.svg";
import bones from "../images/Icons/Bones.svg";
import crown from "../images/Icons/Crown.svg";
import flower from "../images/Icons/Flower.svg";
import heartSingle from "../images/Icons/Heart_Single.svg";
import heartTriple from "../images/Icons/Heart_Triple.svg";
import leaves from "../images/Icons/Leaves.svg";
import mountains from "../images/Icons/Mountains.svg";
import paws from "../images/Icons/Paws.svg";
import peace from "../images/Icons/Peace.svg";
import skull from "../images/Icons/Skull.svg";
import smile from "../images/Icons/Smile.svg";
import squirrel from "../images/Icons/Squirrel.svg";
import starSingle from "../images/Icons/Star_Single.svg";
import starTriple from "../images/Icons/Star_Triple.svg";
import stick from "../images/Icons/Stick.svg";
import stripes from "../images/Icons/Stripes.svg";
import summer from "../images/Icons/Summer.svg";
import trees from "../images/Icons/Trees.svg";
import water from "../images/Icons/Water.svg";
import anchor from "../images/Icons/Anchor.svg";
import bear from "../images/Icons/Bear.svg";
import bone from "../images/Icons/Bone.svg";
import camping from "../images/Icons/Camping.svg";
import dog from "../images/Icons/Dog.svg";
import fish from "../images/Icons/Fish.svg";
import football from "../images/Icons/Football.svg";
import hugsKisses from "../images/Icons/HugsKisses.svg";
import hunting from "../images/Icons/Hunting.svg";
import iceCream from "../images/Icons/IceCream.svg";
import linesShapes from "../images/Icons/LinesShapes.svg";
import music from "../images/Icons/Music.svg";
import poop from "../images/Icons/Poop.svg";
import rainbow from "../images/Icons/Rainbow.svg";
import sailboat from "../images/Icons/Sailboat.svg";
import snow from "../images/Icons/Snow.svg";
import sunshine from "../images/Icons/Sunshine.svg";
import tornado from "../images/Icons/Tornado.svg";

export const allIcons = {
  ball,
  bark,
  bird,
  bones,
  crown,
  flower,
  heartSingle,
  heartTriple,
  leaves,
  mountains,
  paws,
  peace,
  skull,
  smile,
  squirrel,
  starSingle,
  starTriple,
  stick,
  stripes,
  summer,
  trees,
  water,
  anchor,
  bear,
  bone,
  camping,
  dog,
  fish,
  football,
  hugsKisses,
  hunting,
  iceCream,
  linesShapes,
  music,
  poop,
  rainbow,
  sailboat,
  snow,
  sunshine,
  tornado,
};

export const possibleIcons = [
  ball,
  bark,
  bird,
  bones,
  crown,
  flower,
  heartSingle,
  heartTriple,
  leaves,
  mountains,
  paws,
  peace,
  skull,
  smile,
  squirrel,
  starSingle,
  starTriple,
  stick,
  stripes,
  summer,
  trees,
  water,
  anchor,
  bear,
  bone,
  camping,
  dog,
  fish,
  football,
  hugsKisses,
  hunting,
  iceCream,
  linesShapes,
  music,
  poop,
  rainbow,
  sailboat,
  snow,
  sunshine,
  tornado,
];
