export const fontFamily = {
  barriecito: "Barriecito",
  bowlby: "BowlByOne",
  bungee: "BungeeInline",
  holtwood: "HoltwoodOneSc",
  londrina: "LondrinaSolid",
  medula: "MedulaOne",
  pacifico: "Pacifico",
  ranchers: "Ranchers",
  slackey: "Slackey",
  wellfleet: "Wellfleet",
  default: "Pacifico",
};

export const typeStyles = (type) => {
  switch (type) {
    case "barriecito-1":
      return {
        fontFamily: fontFamily.barriecito,
        fontSize: 160,
        style: "single",
      };
    case "barriecito-2":
      return {
        fontFamily: fontFamily.barriecito,
        fontSize: 160,
        style: "double",
      };
    case "barriecito-4":
      return {
        fontFamily: fontFamily.barriecito,
        fontSize: 160,
        style: "alternating",
      };
    case "barriecito-3":
      return {
        fontFamily: fontFamily.barriecito,
        fontSize: 160,
        style: "2-up-flip",
      };
    case "bowlby-1":
      return {
        fontFamily: fontFamily.bowlby,
        fontSize: 160,
        style: "single",
      };
    case "bowlby-2":
      return {
        fontFamily: fontFamily.bowlby,
        fontSize: 160,
        style: "double",
      };
    case "bowlby-4":
      return {
        fontFamily: fontFamily.bowlby,
        fontSize: 160,
        style: "alternating",
      };
    case "bowlby-3":
      return {
        fontFamily: fontFamily.bowlby,
        fontSize: 160,
        offset: 10,
        style: "2-up-flip",
      };
    case "bungee-1":
      return {
        fontFamily: fontFamily.bungee,
        fontSize: 180,
        style: "single",
      };
    case "bungee-2":
      return {
        fontFamily: fontFamily.bungee,
        fontSize: 180,
        style: "double",
      };
    case "bungee-4":
      return {
        fontFamily: fontFamily.bungee,
        fontSize: 180,
        style: "alternating",
      };
    case "bungee-3":
      return {
        fontFamily: fontFamily.bungee,
        fontSize: 180,
        style: "2-up-flip",
      };
    case "holtwood-1":
      return {
        fontFamily: fontFamily.holtwood,
        fontSize: 140,
        style: "single",
      };
    case "holtwood-2":
      return {
        fontFamily: fontFamily.holtwood,
        fontSize: 140,
        style: "double",
      };
    case "holtwood-4":
      return {
        fontFamily: fontFamily.holtwood,
        fontSize: 140,
        style: "alternating",
      };
    case "holtwood-3":
      return {
        fontFamily: fontFamily.holtwood,
        fontSize: 140,
        style: "2-up-flip",
      };
    case "londrina-1":
      return {
        fontFamily: fontFamily.londrina,
        fontSize: 180,
        style: "single",
      };
    case "londrina-2":
      return {
        fontFamily: fontFamily.londrina,
        fontSize: 180,
        style: "double",
      };
    case "londrina-4":
      return {
        fontFamily: fontFamily.londrina,
        fontSize: 180,
        style: "alternating",
      };
    case "londrina-3":
      return {
        fontFamily: fontFamily.londrina,
        fontSize: 180,
        style: "2-up-flip",
      };
    case "medula-1":
      return {
        fontFamily: fontFamily.medula,
        fontSize: 170,
        style: "single",
      };
    case "medula-2":
      return {
        fontFamily: fontFamily.medula,
        fontSize: 170,
        style: "double",
      };
    case "medula-4":
      return {
        fontFamily: fontFamily.medula,
        fontSize: 170,
        style: "alternating",
      };
    case "medula-3":
      return {
        fontFamily: fontFamily.medula,
        fontSize: 170,
        style: "2-up-flip",
      };
    case "pacifico-1":
      return {
        fontFamily: fontFamily.pacifico,
        fontSize: 130,
        offset: 20,
        style: "single",
      };
    case "pacifico-2":
      return {
        fontFamily: fontFamily.pacifico,
        fontSize: 130,
        offset: 20,
        style: "double",
      };
    case "pacifico-4":
      return {
        fontFamily: fontFamily.pacifico,
        fontSize: 130,
        offset: 20,
        style: "alternating",
      };
    case "pacifico-3":
      return {
        fontFamily: fontFamily.pacifico,
        fontSize: 130,
        offset: 20,
        style: "2-up-flip",
      };
    case "ranchers-1":
      return {
        fontFamily: fontFamily.ranchers,
        fontSize: 160,
        style: "single",
      };
    case "ranchers-2":
      return {
        fontFamily: fontFamily.ranchers,
        fontSize: 160,
        style: "double",
      };
    case "ranchers-4":
      return {
        fontFamily: fontFamily.ranchers,
        fontSize: 160,
        style: "alternating",
      };
    case "ranchers-3":
      return {
        fontFamily: fontFamily.ranchers,
        fontSize: 160,
        style: "2-up-flip",
      };
    case "slackey-1":
      return {
        fontFamily: fontFamily.slackey,
        fontSize: 160,
        style: "single",
      };
    case "slackey-2":
      return {
        fontFamily: fontFamily.slackey,
        fontSize: 160,
        style: "double",
      };
    case "slackey-4":
      return {
        fontFamily: fontFamily.slackey,
        fontSize: 160,
        style: "alternating",
      };
    case "slackey-3":
      return {
        fontFamily: fontFamily.slackey,
        fontSize: 160,
        style: "2-up-flip",
      };
    case "wellfleet-1":
      return {
        fontFamily: fontFamily.wellfleet,
        fontSize: 160,
        style: "single",
      };
    case "wellfleet-2":
      return {
        fontFamily: fontFamily.wellfleet,
        fontSize: 160,
        style: "double",
      };
    case "wellfleet-4":
      return {
        fontFamily: fontFamily.wellfleet,
        fontSize: 160,
        style: "alternating",
      };
    case "wellfleet-3":
      return {
        fontFamily: fontFamily.wellfleet,
        fontSize: 160,
        style: "2-up-flip",
      };
    default:
      return {
        fontFamily: fontFamily.pacifico,
        fontSize: 130,
        style: "single",
      };
  }
};

export default typeStyles;
