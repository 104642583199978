import { faUtensilFork } from "@fortawesome/fontawesome-pro-light";
import { atom, atomFamily, DefaultValue } from "recoil";
// import { useQueryParam, StringParam } from "use-query-params";

export const urlEffect = (key) => ({ setSelf, onSet }) => {
  // console.log("url effect");
  // const [keyParam, setKeyParam] = useQueryParam(key, StringParam);
  // if (keyParam != null) {
  //   setSelf(JSON.parse(keyParam));
  // }
  // onSet((newValue) => {
  //   console.log("key param onset");
  //   setKeyParam(JSON.stringify(newValue));
  // });
  /// ///////////
  // const isBrowser = typeof window !== "undefined";
  // if (isBrowser) {
  //   const savedValue = window.localStorage.getItem(key);
  //   if (savedValue != null) {
  //     setSelf(JSON.parse(savedValue));
  //   }
  //   onSet((newValue) => {
  //     if (newValue) {
  //       window.localStorage.removeItem(key);
  //     } else {
  //       window.localStorage.setItem(key, JSON.stringify(newValue));
  //     }
  //   });
  // }
};

const localPersist = ({ onSet, setSelf, node }) => {
  // console.log("local persist");
  // const storedItems = localStorage.getItem(node.key);
  // if (storedItems != null) {
  //   setSelf(JSON.parse(storedItems));
  // }
  // onSet((newItems) => {
  // if (newItems instanceof DefaultValue) {
  //   localStorage.removeItem(node.key);
  // } else {
  // localStorage.setItem(node.key, JSON.stringify(newItems));
  // }
  // });
};

// ////////

export const selectedItemAtom = atom({
  key: "selectedItem",
  default: null,
  effects_UNSTABLE: [localPersist],
});

export const cartAtom = atom({
  key: "ids",
  default: [],
  effects_UNSTABLE: [localPersist],
});

export const itemAtom = atomFamily({
  key: "item",
  default: {
    name: "",
    product: "collar",
    design: "DIY",
    size: { label: "Regular", value: "regular" },
    font: { label: "Holtwood", value: "holtwood" },
    typestyle: { label: "One Line", value: "1" },
    icons: [],
    color: JSON.stringify({
      name: "default",
      primaryColor: "#000000",
      secondaryColor: "#999999",
      backgroundColor: "#ffffff",
      iconColor: "#000000",
      textColor: "null",
    }),
    reflection: false,
    lightsOn: false,
    includeSpid: false,
    spid: [],
    removeDRing: false,
  },
  effects_UNSTABLE: [localPersist],
});

export const spidItemAtom = atomFamily({
  key: "spidItem",
  default: {
    product: "collar",
    pattern: false,
    backgroundColor: false,
    textColor: false,
    size: { label: "Regular", value: "regular" },
    reflection: false,
    lightsOn: false,
    includeSpid: true,
    spid: [],
    removeDRing: false,
  },
  effects_UNSTABLE: [localPersist],
});

// // // // //

export const introStepAtom = atom({
  key: "introStep",
  default: "intro-name",
  effects_UNSTABLE: [urlEffect("introStep")],
});

export const customStepAtom = atom({
  key: "customStep",
  default: "custom-name",
  effects_UNSTABLE: [urlEffect("customStep")],
});

export const spidStepAtom = atom({
  key: "spidStep",
  default: "spid-pattern",
  effects_UNSTABLE: [urlEffect("spidStep")],
});

export const showCartAtom = atom({
  key: "showCart",
  default: false,
  effects_UNSTABLE: [urlEffect("showCart")],
});
