import { selector, selectorFamily } from "recoil";
import { compact, startsWith, isObject } from "lodash";

import { itemAtom, spidItemAtom, cartAtom } from "./atoms";

const itemToPrice = ({ type, includeSpid, includeReflection }) => {
  let price = 0;
  if (type === "cl") {
    price += 45;
  } else {
    price += 38;
  }
  if (includeSpid) price += 3;
  if (includeReflection) price += 3;
  return price;
};

export const collarlabFromIdSelector = selectorFamily({
  key: "collarlabOrder",
  get: ({ id }) => ({ get }) => {
    const item = get(itemAtom(id));
    let width = item.size.value.width || 1;
    // let length = item.size.value.length / 2 ||  24 / 2;
    let length = 24 / 2;

    if (item.size.value === "small") {
      width = 0.75;
      length = 10.38;
    }
    if (item.size.value === "regular") {
      width = 1;
      length = 12.96;
    }
    if (item.size.value === "large") {
      width = 1.5;
      length = 19.68;
    }

    return {
      product: "collarlab",
      name: item.name,
      width,
      length,
      type: `${item.font.value}-${item.typestyle.value}`,
      icon: item.icons,
      primaryColor: JSON.parse(item.color).primaryColor,
      secondaryColor: JSON.parse(item.color).secondaryColor,
      backgroundColor: JSON.parse(item.color).backgroundColor,
      iconColor: JSON.parse(item.color).iconColor,
      reflection: item.reflection,
      lightsOn: item.lightsOn,
      includeSpid: item.includeSpid,
      spid: compact(item.spid),
      removeDRing: item.removeDRing,
    };
  },
});

export const spidFromIdSelector = selectorFamily({
  key: "spidOrder",
  get: ({ id }) => ({ get }) => {
    const item = get(spidItemAtom(id));
    let width = item.size.value.width || 1;
    // let length = item.size.value.length / 2 ||  24 / 2;
    let length = 24 / 2;

    if (item.size.value === "small") {
      width = 0.75;
      length = 10.38;
    }
    if (item.size.value === "regular") {
      width = 1;
      length = 12.96;
    }
    if (item.size.value === "large") {
      width = 1.5;
      length = 19.68;
    }

    return {
      product: "spid",
      width,
      length,
      patternLabel: item.pattern.label,
      pattern: item.pattern.img,
      backgroundColor: item.backgroundColor || '#ffffff',
      textColor: item.textColor,
      reflection: item.reflection,
      lightsOn: item.lightsOn,
      includeSpid: item.includeSpid,
      spid: compact(item.spid),
      removeDRing: item.removeDRing,
    };
  },
});

export const priceFromIdSelector = selectorFamily({
  key: "price",
  get: ({ id }) => ({ get }) => {
    let price = 0
    if (startsWith(id, "cl")) {
      const item = get(itemAtom(id));
      price = itemToPrice({
        type: startsWith(id, "cl") ? "cl" : "sp",
        includeSpid: item.includeSpid,
        includeReflection: item.reflection,
      });
    }
    if (startsWith(id, "sp")) {
      const item = get(spidItemAtom(id));
      price = itemToPrice({
        type: "sp",
        includeSpid: false,
        includeReflection: item.reflection,
      });
    }
    return price
  },
});

export const subtotalFromCartSelector = selector({
  key: "subtotal",
  get: ({ get }) => {
    const cart = get(cartAtom);
    let subtotal = 0;

    cart.map((id) => {
      if (startsWith(id, "cl")) {
        const item = get(itemAtom(id));
        const price = itemToPrice({
          type: "cl",
          includeSpid: item.includeSpid,
          includeReflection: item.reflection,
        });
        subtotal += price;
      }
      if (startsWith(id, "sp")) {
        const item = get(spidItemAtom(id));
        const price = itemToPrice({
          type: "sp",
          includeSpid: false,
          includeReflection: item.reflection,
        });
        subtotal += price;
      }
      return subtotal;
    });
    return subtotal;
  },
});

export const checkoutFromCartSelector = selector({
  key: "checkout",
  get: ({ get }) => {
    const cart = get(cartAtom);
    const checkout = []
    
    cart.map((id) => {
      // todo: map lineitems for customattributes
      if (startsWith(id, "cl")) {
        const item = get(itemAtom(id));
        // base: Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTM2MzQ1OTY3ODMwNg==
        // reflection: Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTM2MzQ1OTc0Mzg0Mg==
        // spid: Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTM2MzQ1OTY0NTUzOA==
        // both: Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTM2MzQ1OTcxMTA3NA==

        let variantId = ""                
        if (item.reflection && item.includeSpid && item.spid.length > 0) {
          variantId = "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTM2MzQ1OTcxMTA3NA=="
        } else if (item.reflection) {
          variantId = "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTM2MzQ1OTc0Mzg0Mg=="
        } else if (item.includeSpid && item.spid.length > 0) {
          variantId = "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTM2MzQ1OTY0NTUzOA=="
        } else {
          variantId = "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTM2MzQ1OTY3ODMwNg=="
        }

        const sz = isObject(item.size.value) ? JSON.stringify(item.size.value) : item.size.label

        const { 
          name,
          font,
          typestyle,
          size,
          color,
          icons,
          includeSpid,
          spid,
          reflection,
        } = item
        checkout.push({
          variantId,
          quantity: 1,
          customAttributes: [
            {
              key: "id",
              value: id,
            },
            {
              key: "pet_name",
              value: name,
            },
            {
              key: "Font",
              value: font.label,
            },
            {
              key: "font_style",
              value: typestyle.label,
            },
            {
              key: "size",
              value: sz,
            },
            {
              key: "color",
              value: JSON.parse(color).name,
            },
            {
              key: "Icons",
              value: icons.map(({value}) => value).join(', '),
            },
            {
              key: "reflective",
              value: reflection.toString(),
            },
            {
              key: "Line1",
              value: includeSpid && spid.length > 0 ? spid[0] : "",
            },
            {
              key: "Line2",
              value: includeSpid && spid.length > 1 ? spid[1] : "",
            },
            {
              key: "Line3",
              value: includeSpid && spid.length > 2 ? spid[2] : "",
            },
            {
              key: "Line4",
              value: includeSpid && spid.length > 3 ? spid[3] : "",
            },
            {
              key: "Line5",
              value: includeSpid && spid.length > 4 ? spid[4] : "",
            },
            {
              key: "remove_d_ring",
              value: item.removeDRing.toString(),            
            }
          ],        
        })
      }
      if (startsWith(id, "sp")) {
        const item = get(spidItemAtom(id));
        // base: Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTM2MzQ1OTU0NzIzNA==
        // reflection: Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTM2MzQ1OTYxMjc3MA==
        // spid: Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTM2MzQ1OTUxNDQ2Ng==
        // both: Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTM2MzQ1OTU4MDAwMg==

        let variantId = ""
        if (item.reflection && item.includeSpid && item.spid.length > 0) {
          variantId = "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTM2MzQ1OTU4MDAwMg=="
        } else if (item.reflection) {
          variantId = "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTM2MzQ1OTYxMjc3MA=="
        } else if (item.includeSpid && item.spid.length > 0) {
          variantId = "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTM2MzQ1OTUxNDQ2Ng=="
        } else {
          variantId = "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTM2MzQ1OTU0NzIzNA=="
        }

        const sz = isObject(item.size.value) ? JSON.stringify(item.size.value) : item.size.label

        const { 
          pattern,
          size,
          includeSpid,
          spid,
          reflection,
        } = item
        checkout.push({
          variantId,
          quantity: 1,
          customAttributes: [
            {
              key: "id",
              value: id,
            },
            {
              key: "pattern",
              value: pattern.value,
            },
            {
              key: "size",
              value: sz,
            },
            {
              key: "reflective",
              value: reflection.toString(),
            },
            {
              key: "Line1",
              value: includeSpid && spid.length > 0 ? spid[0] : "",
            },
            {
              key: "Line2",
              value: includeSpid && spid.length > 1 ? spid[1] : "",
            },
            {
              key: "Line3",
              value: includeSpid && spid.length > 2 ? spid[2] : "",
            },
            {
              key: "Line4",
              value: includeSpid && spid.length > 3 ? spid[3] : "",
            },
            {
              key: "Line5",
              value: includeSpid && spid.length > 4 ? spid[4] : "",
            },
            {
              key: "remove_d_ring",
              value: item.removeDRing.toString(),            
            }
          ],        
        })  
      }
    })
    return checkout
  }
})

export const checkoutOrderFromCartSelector = selector({
  key: "checkoutOrder",
  get: ({ get }) => {
    const cart = get(cartAtom);
  
    const checkout = cart.map((id) => {
      if (startsWith(id, "cl")) {
        const item = get(itemAtom(id));
        const price = itemToPrice({
          type: startsWith(id, "cl") ? "cl" : "sp",
          includeSpid: item.includeSpid,
          includeReflection: item.reflection,
        }); 
        const { 
          name,
          font,
          typestyle,
          size,
          color,
          icons,
          includeSpid,
          spid,
          reflection,
          removeDRing,
        } = item
        return {
          name,
          font: font.label,
          typestyle: typestyle.label,
          size: size.label,
          color: JSON.parse(color).name,
          icons,
          spid: includeSpid ? spid : [],
          reflection,
          price,
          removeDRing,
        }
      }
      if (startsWith(id, "sp")) {
        const item = get(spidItemAtom(id));
      }
    });
    return checkout
  },
});

export default collarlabFromIdSelector;
