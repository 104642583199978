import React, { useRef, useEffect } from "react";
import { navigate } from "gatsby";
import { get, flatten, reduce, without, isEmpty, startsWith } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/fontawesome-pro-light";

import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";

import CollarlabImg from "../CollarlabImg/CollarlabImg";
import SpidImg from "../SpidImg/SpidImg";
import StepWrap from "../StepWrap/StepWrap";
import { useShopifyFunctions } from "../../utils/useShopifyFunctions";
import {
  itemAtom,
  cartAtom,
  customStepAtom,
  selectedItemAtom,
  showCartAtom,
  introStepAtom,
} from "../../utils/atoms";
import {
  collarlabFromIdSelector,
  priceFromIdSelector,
  subtotalFromCartSelector,
  checkoutFromCartSelector,
  checkoutOrderFromCartSelector,
  spidFromIdSelector,
} from "../../utils/selectors";
import plainCollar from "../../images/collarlab-bg-1.png";

const LineItemCl = ({ id }) => {
  const collarlab = useRecoilValue(collarlabFromIdSelector({ id }));
  const price = useRecoilValue(priceFromIdSelector({ id }));
  const info = useRecoilValue(itemAtom(id));
  const [cart, setCart] = useRecoilState(cartAtom);
  const setShowCart = useSetRecoilState(showCartAtom);
  const setCustomStep = useSetRecoilState(customStepAtom);
  const setSelectedItem = useSetRecoilState(selectedItemAtom);

  return (
    <div className="flex justify-between items-center">
      <div className="w-1/2">
        <span>
          <b className="font-bold">Design Your Own</b> - {info.name}
        </span>
        <CollarlabImg
          name={collarlab.name}
          sz={1}
          type={collarlab.type}
          backgroundColor={collarlab.backgroundColor}
          primaryColor={collarlab.primaryColor}
          secondaryColor={collarlab.secondaryColor}
          iconColor={collarlab.iconColor}
          // type="collar"
          icon={collarlab.icon}
          placeholder=""
          size="3rem"
        />
        {/* <span>
          {info.name} - {JSON.parse(info.color).name} - {info.font.label} -{" "}
          {info.icons} - {info.size.label} - {info.spid} -{" "}
          {info.typestyle.label}
        </span> */}
      </div>
      <div>
        <span className="text-lg">${price}.00</span>
      </div>
      <button
        className="m-2"
        type="button"
        onClick={() => {
          setSelectedItem(id);
          setCustomStep("custom-summary");
          navigate("/collarlab-custom");
          setShowCart(false);
        }}
      >
        <FontAwesomeIcon icon={faEdit} />
        <br />
        <span className="text-xs uppercase">edit</span>
      </button>
      <button
        className="m-2"
        type="button"
        onClick={() => {
          setCart(without(cart, id));
        }}
      >
        <FontAwesomeIcon icon={faTrash} />
        <br />
        <span className="text-xs uppercase">remove</span>
      </button>
    </div>
  );
};

const LineItemSp = ({ id }) => {
  const spid = useRecoilValue(spidFromIdSelector({ id }));
  const price = useRecoilValue(priceFromIdSelector({ id }));
  const [cart, setCart] = useRecoilState(cartAtom);
  const setShowCart = useSetRecoilState(showCartAtom);
  // const setCustomStep = useSetRecoilState(customStepAtom);
  const setSelectedItem = useSetRecoilState(selectedItemAtom);

  return (
    <div className="flex justify-between items-center">
      <div className="w-1/2">
        <span>
          <b className="font-bold">Patterns</b> - {spid.patternLabel}
        </span>
        <SpidImg
          sz={1}
          // type="collar"
          pattern={spid.pattern}
          placeholder=""
          size="3rem"
        />
      </div>
      <div>
        <span className="text-lg">${price}.00</span>
      </div>
      <button
        className="m-2"
        type="button"
        onClick={() => {
          setSelectedItem(id);
          // setCustomStep("custom-summary");
          navigate("/spid-custom");
          setShowCart(false);
        }}
      >
        <FontAwesomeIcon icon={faEdit} />
        <br />
        <span className="text-xs uppercase">edit</span>
      </button>
      <button
        className="m-2"
        type="button"
        onClick={() => {
          setCart(without(cart, id));
        }}
      >
        <FontAwesomeIcon icon={faTrash} />
        <br />
        <span className="text-xs uppercase">remove</span>
      </button>
    </div>
  );
};

const Cart = () => {
  const { resetCart, addItems, getCheckout } = useShopifyFunctions();
  const [cart] = useRecoilState(cartAtom);
  const subtotal = useRecoilValue(subtotalFromCartSelector);
  const allItems = useRecoilValue(checkoutFromCartSelector);
  const allItemsOrder = useRecoilValue(checkoutOrderFromCartSelector);
  const setShowCart = useSetRecoilState(showCartAtom);
  const setSelectedItem = useSetRecoilState(selectedItemAtom);
  const setIntroStep = useSetRecoilState(introStepAtom);

  const handleCheckout = () => {
    resetCart();
    addItems(allItems).then(() =>
      getCheckout().then((res) => {
        if (window.parent && window.parent !== window.top) {
          // console.log({ shopify: { checkoutToken: res.id } });
          window.parent.postMessage(
            JSON.stringify({
              command: "esuite_app",
              data: {
                app: "self_checkout",
                instanceId: 5,
                action: "start_checkout",
                data: { shopify: { checkoutToken: res.id } },
              },
            }),
            "*"
          );
        } else {
          // redirect to Shopify checkout
          navigate("/qr", {
            state: { checkoutUrl: res.webUrl },
          });
          setShowCart(false);
          // window.location.href = res.webUrl;
        }
      })
    );
  };

  return (
    <div className="flex flex-col h-full">
      <StepWrap title="Cart">
        {isEmpty(cart) && (
          <div className="text-center text-lg my-16">Cart is empty</div>
        )}
        <ul>
          {cart.map((lineItem) => (
            <li className="border-b py-4" key={lineItem}>
              {startsWith(lineItem, "cl") && <LineItemCl id={lineItem} />}
              {startsWith(lineItem, "sp") && <LineItemSp id={lineItem} />}
            </li>
          ))}
        </ul>
        <div className="mt-16">
          <h3 className="text-lg font-bold mx-4 text-center pb-2">
            {" "}
            {isEmpty(cart) ? "Add a Collar" : "Add Another Collar"}
          </h3>
          <button
            type="button"
            className={`${
              cart.length > 0 ? "border-2" : "bg-sp-orange text-white"
            }
            w-full text-xl font-medium rounded-full m-2 px-8 py-4`}
            onClick={() => {
              setSelectedItem(null);
              setIntroStep("intro-name");
              setShowCart(false);
              navigate("/collarlab-intro");
            }}
          >
            Design Your Own Collar
          </button>
          <button
            type="button"
            className={`${
              cart.length > 0 ? "border-2" : "bg-sp-orange text-white"
            }
            w-full text-xl font-medium rounded-full m-2 px-8 py-4`}
            onClick={() => {
              setSelectedItem(null);
              setShowCart(false);
              navigate("/spid-grid");
            }}
          >
            Use Our Patterns
          </button>
        </div>
      </StepWrap>

      <div className="relative bg-sp-gray w-full flex flex-col items-center h-32 p-8 pt-4">
        <div className="text-sm font-bold">
          You have {cart.length} items in your cart: ${subtotal}.00
        </div>
        <div className="text-sm">
          Shipping, taxes, and discounts will be calculated at checkout.
        </div>
        <div className="flex">
          <button
            type="button"
            className={`${
              cart.length > 0
                ? "bg-sp-orange"
                : "bg-opacity-20 bg-black cursor-not-allowed"
            }
              w-full text-xl font-medium rounded-full text-white mt-2 px-8 py-2 ml-2`}
            onClick={handleCheckout}
          >
            Checkout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cart;
